import NationalParkMap from './Posts/NationalParkMap/NationalParkMap';
import ScrapeHackerNews from './Posts/ScrapeHackerNews/ScrapeHackerNews';
import YouTubeTrendAnalysis from './Posts/YouTubeTrendAnalysis/YouTubeTrendAnalysis';
import StackOverflowExtension from './Posts/StackOverflowExtension/StackOverflowExtension';

const PROJECTS = [
	// {
	// 	title: 'Learn Chrome Extension Development (Udemy)',
	// 	link: 'https://www.udemy.com/course/build-chrome-extensions/?referralCode=062F091C3D4F08A95558',
	// 	path: '/chrome-extensions',
	// 	udemy: true,
	// },
	{
		title: 'How to Make Custom Maps with React',
		path: '/react-google-maps',
		component: NationalParkMap,
	},
	{
		title: 'How to Analyze YouTube Data with React, Node.js, & Chart.js',
		path: '/youtube-data-react-node-js',
		component: YouTubeTrendAnalysis,
	},
	{
		title: 'How to Make a Minimalist Stack Overflow Theme',
		path: '/minimalist-theme-chrome-extension',
		component: StackOverflowExtension,
	},
	{
		title: 'How to Scrape Websites with Node.js',
		path: '/web-scraping-node-js',
		component: ScrapeHackerNews,
	},
];

export default PROJECTS;
