import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Navbar from './components/Navbar/Navbar';
import Projects from './components/Projects/Projects';
import About from './components/About/About';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
    faCode,
    faHome,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

import './App.css';

import projectList from './components/Projects/project-list';

library.add(fab, faCode, faHome, faInfoCircle);

function App() {
    const routeComponents = projectList.map(({ path, component }, key) => (
        <Route exact path={path} component={component} key={key} />
    ));

    return (
        <Router>
            <ScrollToTop />
            <Navbar />
            <div className='page'>
                <Route exact path='/'>
                    <Projects />
                </Route>
                <Route exact path='/about'>
                    <About />
                </Route>
                {routeComponents}
            </div>
        </Router>
    );
}

export default App;
