const code = {
    importText: `const fetch = require('node-fetch');
const fs = require('fs');`,
    config: `module.exports = {
    YOUTUBE_API_KEY: "[add your key here]",
};`,
    api: `const config = require('./config.js');
const url = 'https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&chart=mostPopular&regionCode=US&key=' + config.YOUTUBE_API_KEY + '&maxResults=50';`,
    apiData: `fetch(url)
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        fs.writeFile('youtube-api-data.json', JSON.stringify(data), function (err) {
            if (err) throw err;
        });
    });`,
    exampleData: ` 
    {
        "kind": "youtube#video",
        "etag": "yC5-5Xihnz_5HOfsdUF_U22BlpU",
        "id": "4UFfD0HIhv4",
        "snippet": {
            "publishedAt": "2020-10-18T07:14:30Z",
            "channelId": "UCqFzWxSCi39LnW1JKFR3efg",
            "title": "Dueling Town Halls Cold Open - SNL",
            "description": "George Stephanopoulos (Mikey Day) hosts a town hall..."
            "..."
    }`,
    videoProcessing: `let videos = [];
for (let i = 0; i < data['items'].length; i++) {
    const video = data['items'][i];
    let videoObject = {
        channel: video.snippet.channelTitle,
        title: video.snippet.title,
        description: video.snippet.description,
        duration: video.contentDetails.duration,
        publishedAt: video.snippet.publishedAt,
        category: video.snippet.categoryId,
        statistics: video.statistics,
    };
    videos.push(videoObject);
}`,
    writeProcessedFile: `fs.writeFile('processed-youtube-data.json', JSON.stringify(videos), function (err) {
    if (err) throw err;
});`,
    videoData: `"1": {
    "channel": "Dude Perfect",
    "title": "GIANT Pumpkin Carving Contest",
    "description": "Hide and seek in a HUGE store, carving massive pumpkins, plus..."
    "duration": "PT25M49S",
    "publishedAt": "2020-10-19T22:00:05Z",
    "category": "17",
    "statistics": {
        "viewCount": "4556582",
        "likeCount": "284182",
        "dislikeCount": "3378",
        "favoriteCount": "0",
        "commentCount": "15573"
    }
},
"2": {`,
    categories: `const fetch = require('node-fetch');
const fs = require('fs');
const config = require('./config.js');

const youtubeKey = config.YOUTUBE_API_KEY;

let url = 'https://www.googleapis.com/youtube/v3/videoCategories?part=snippet&regionCode=US&key=' + youtubeKey;

fetch(url)
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        fs.writeFile(
            'youtube-categories-data.json',
            JSON.stringify(data),
            function (err) {
                if (err) throw err;
            }
        );
        console.log('Saved JSON file');
    });
`,
    categoryData: `{
    "kind": "youtube#videoCategoryListResponse",
    "etag": "HIrK3n45Uw2IYz9_U2-gK1OsXvo",
    "items": [
        {
            "kind": "youtube#videoCategory",
            "etag": "IfWa37JGcqZs-jZeAyFGkbeh6bc",
            "id": "1",
            "snippet": {
                "title": "Film & Animation",
                "assignable": true,
                "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
            }
        },
        {
            "kind": "youtube#videoCategory",
            "etag": "5XGylIs7zkjHh5940dsT5862m1Y",
            "id": "2",
            "snippet": {
                "title": "Autos & Vehicles"...`,
    processCategories: `let categoryMap = {};
let categories = data['items'];
for (let i = 0; i < categories.length; i++) {
    console.log(categories[i]);
    categoryMap[categories[i].id] = categories[i].snippet.title;
}`,
    categoryMap: `{
    "1": "Film & Animation",
    "2": "Autos & Vehicles",
    "10": "Music",
    "15": "Pets & Animals",
    "17": "Sports",
    "18": "Short Movies",
    "19": "Travel & Events",
    "20": "Gaming",
    "21": "Videoblogging",
    "22": "People & Blogs"...`,
    analyzeCategories: `const fs = require('fs');

let rawYoutubeData = fs.readFileSync('processed-youtube-data.json');
let youtubeData = JSON.parse(rawYoutubeData);

let rawCategoryData = fs.readFileSync('processed-categories.json');
let categoryData = JSON.parse(rawCategoryData);

let categoryCounts = {};

for (let i = 0; i < youtubeData.length; i++) {
    let categoryId = youtubeData[i].category;
    let categoryName = categoryData[categoryId];

    if (!categoryCounts[categoryName]) {
        categoryCounts[categoryName] = 1;
    } else {
        categoryCounts[categoryName]++;
    }
}`,
    categoryCounts: `{
    "People & Blogs": 6,
    "Music": 12,
    "Comedy": 3,
    "Film & Animation": 2,
    "Entertainment": 8,
    "Gaming": 4,
    "Sports": 5,
    "Autos & Vehicles": 4,
    "Science & Technology": 4,
    "Howto & Style": 1,
    "Education": 1
}`,
    categoryComponent: `import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { HorizontalBar } from 'react-chartjs-2';

const CategoryChart = () => {

};

export default CategoryChart;`,
    processChartData: `let categories = {
    'People & Blogs': 6,
    'Music': 12,
    'Comedy': 3,
    'Film & Animation': 2,
    'Entertainment': 8,
    'Gaming': 4,
    'Sports': 5,
    'Autos & Vehicles': 4,
    'Science & Technology': 4,
    'Howto & Style': 1,
    'Education': 1,
};

let arr = [];

for (let item in categories) {
    arr.push([item, categories[item]]);
}

arr.sort(function (a, b) {
    return b[1] - a[1];
});

let labels = [];
let nums = [];
for (let i = 0; i < arr.length; i++) {
    labels.push(arr[i][0]);
    nums.push(arr[i][1]);
}`,
    categoryOptions: `const data = {
    labels: labels,
    datasets: [
        {
            label: null,
            data: nums,
            backgroundColor: 'steelblue',
        },
    ],
};

const options = {
    plugins: {
        datalabels: {
            color: '#fff',
        },
    },
    legend: {
        display: false,
    },
    scales: {
        xAxes: [
            {
                gridLines: {
                    display: true,
                    drawBorder: false,
                    borderDash: [3, 3],
                },
                labels: {
                    display: true,
                },
                categoryPercentage: 0.7,
                barPercentage: 0.9,
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
        yAxes: [
            {
                display: true,
                gridLines: {
                    display: false,
                },
            },
        ],
    },
};`,
    returnCategoryChart: `return (
    <HorizontalBar
        data={data}
        options={options}
        plugins={[ChartDataLabels]}
    />
);`,
};

export default code;
