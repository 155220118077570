import React, { useEffect } from 'react';
import NewTab from '../../../Shared/NewTab/NewTab';
// import YouTube from '../../../Shared/YouTube/YouTube';
// import Github from '../../../Shared/Github/Github';
import CodeBlock from '../../../Shared/CodeBlock/CodeBlock';
import ModalImage from 'react-modal-image';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-json';
import code from './code';

import '../posts.css';

const ScrapeHackerNews = () => {
	useEffect(() => {
		Prism.highlightAll();
	});

	return (
		<div className='post'>
			<div className='post__title'>
				How to Scrape Websites with Node.js
			</div>
			{/* <Github repo='puppeteer-snippets' /> */}
			{/* <YouTube url={'https://www.youtube.com/embed/ZZ3N4jl2D6s'} /> */}
			<div className='post__container'>
				<p className='post__paragraph'>
					Web-scraping is a powerful ability. It allows you to get
					content from a website and use it as needed. You can scrape
					the web with multiple languages and libraries (such as
					Python/Beautiful Soup). But this tutorial shows how to use
					Node.js and Puppeteer to scrape data from
					<NewTab
						text='Hacker News.'
						url='https://news.ycombinator.com/'
					/>
				</p>
				<p className='post__paragraph'>
					The first step is to install
					<NewTab
						text='Node.js.'
						url='https://nodejs.org/en/download/'
					/>
					Then install the Puppeteer library as instructed
					<NewTab
						text='here.'
						url='https://www.npmjs.com/package/puppeteer'
					/>
				</p>
				<p className='post__paragraph'>
					So first we just create a new JavaScript file and import the
					Puppeteer library. We also import the fs library to use for
					writing a JSON file.
				</p>
				<CodeBlock language='js' code={code.importText} />
				<p className='post__paragraph'>
					Now we want to create a try-catch block within an async
					function.
				</p>
				<CodeBlock language='js' code={code.tryCatch} />
				<p className='post__paragraph'>
					Within the try block, create a variable for launching the
					headless browser. Then create a page variable for opening a
					new page. Next you will await the page URL (in this example,
					<NewTab
						text='https://news.ycombinator.com'
						url='https://news.ycombinator.com'
					/>
					or 'Hacker News').
				</p>
				<CodeBlock language='js' code={code.tryBlock} />
				<p className='post__paragraph'>
					If you inspect elements on the home page, you'll see the
					class 'storylink' in use there.
				</p>
				<ModalImage
					className='post__screenshot national-park-map'
					small='../images/projects/scrape-hacker-news/storylink.png'
					large='../images/projects/scrape-hacker-news/storylink.png'
				/>
				<p className='post__paragraph'>
					At this point we can retrieve the content that we are
					targeting. In this example, we will get the title, link, and
					age. You can target any data that you want. But keep in mind
					that the elements and classnames will vary between different
					websites.
				</p>
				<CodeBlock language='js' code={code.evaluate} />
				<p className='post__paragraph'>
					Now we can close the browser and save our file, converting
					the news object to JSON using JSON.stringify.
				</p>
				<CodeBlock language='js' code={code.saveFile} />
				<p className='post__paragraph'>
					And now you have a nicely formatted JSON file with this
					structure. You could use this data to analyze patterns or
					design your own layout.
				</p>
				<CodeBlock language='json' code={code.jsonData} />
				<p className='post__paragraph'>
					And there you have it! A simple but practical use case for
					web-scraping. Below is all the code used:
				</p>
				<CodeBlock language='js' code={code.allCode} />
			</div>
		</div>
	);
};

export default ScrapeHackerNews;
