import React, { useEffect } from 'react';
import NewTab from '../../../Shared/NewTab/NewTab';
// import YouTube from '../../../Shared/YouTube/YouTube';
// import Github from '../../../Shared/Github/Github';
import CodeBlock from '../../../Shared/CodeBlock/CodeBlock';
import InlineCode from '../../../Shared/InlineCode/InlineCode';
import ModalImage from 'react-modal-image';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

import code from './code';

import './NationalParkMap.css';
import '../posts.css';

const NationalParkMap = () => {
	useEffect(() => {
		Prism.highlightAll();
	});

	return (
		<div className='post'>
			<div className='post__title'>
				How to Make Custom Maps with React
			</div>
			{/* <Github repo='national-park-map' /> */}
			{/* <YouTube url={'https://www.youtube.com/embed/ZZ3N4jl2D6s'} /> */}
			<div className='post__container'>
				<p className='post__paragraph'>
					I want to visit every national park in the United States one
					day. Using React and the
					<NewTab
						text='Google Maps API,'
						url='https://developers.google.com/maps/documentation'
					/>
					I created an interactive map of national parks to help with
					planning those trips.
				</p>
				<ModalImage
					className='post__screenshot national-park-map'
					small='../images/projects/react-google-maps/national-park-map.png'
					large='../images/projects/react-google-maps/national-park-map.png'
				/>
				<p className='post__paragraph'>
					To make this map, we first want to follow the directions for
					<NewTab
						text='Create React App'
						url='https://reactjs.org/docs/create-a-new-react-app.html'
					/>
					to get a basic React app up and running. Next we install the
					<NewTab
						text='react-google-maps'
						url='https://www.npmjs.com/package/react-google-maps'
					/>
					package through npm. Now you can create a new component file
					called <strong>Map.js</strong> in the src directory. You'll
					want to import the following things at the top of this file.
				</p>
				<CodeBlock language='js' code={code.importText} />
				<p className='post__paragraph'>
					Next you want to create the functional Map component. Note
					the <InlineCode language='js' text='defaultZoom' />
					level, and <InlineCode language='js' text='defaultCenter' />
					option. I chose the center of the United States for this
					latitude/longitude focus of this map.
				</p>
				<CodeBlock language='js' code={code.mapFunction} />
				<p className='post__paragraph'>
					Once we have the map view, we can add our markers. You will
					need the latitude and longitude coordinates of whichever
					points you want to show. I found the national park data
					<NewTab
						text='here on Wikipedia.'
						url='https://en.wikipedia.org/wiki/List_of_national_parks_of_the_United_States'
					/>
					Rather than copy and paste the data, I created Node.js
					scripts to scrape and process this data. You can read more
					about web-scraping in
					<NewTab
						text='this tutorial.'
						url='https://learncodebydoing.com/web-scraping-node-js'
					/>
					You can now create a <strong>parks.js</strong> file with
					this format.
				</p>
				<CodeBlock language='js' code={code.parkList} />
				<p className='post__paragraph'>
					Now you can import the parks array and use the{' '}
					<strong>.map()</strong> function to loop through it. We want
					to add Marker elements for each item. Note the props passed
					to this component. Each Marker needs a unique{' '}
					<strong>key</strong>, in this case the latitude/longitude.
					Then we want to pass those coordinates to the{' '}
					<strong>position</strong>. We also have an{' '}
					<strong>onClick</strong> function for selecting a specific
					park. And finally there is an <strong>icon</strong> prop for
					the tree image source and size.
				</p>
				<CodeBlock language='js' code={code.parkMapping} />
				<p className='post__paragraph'>
					That
					<InlineCode language='js' text='setSelectedPark()' />
					function isn't created yet, so we're going to add that at
					the top of our component. The selected park is null by
					default. But clicking on a park will update the selected
					park to whichever one was clicked.
				</p>
				<CodeBlock language='js' code={code.hookFunction} />
				<p className='post__paragraph'>
					Now we can show an InfoWindow whenever a park is selected.
					The position here should be the same as the selected park.
					And clicking close should reset the selected park value back
					to null.
				</p>
				<CodeBlock language='js' code={code.infoWindow} />
				<p className='post__paragraph'>
					And if we want to add custom styles to our map, we can use a
					site like
					<NewTab text='Snazzy Maps.' url='https://snazzymaps.com/' />
					You just click on a map style that you like, copy the
					JavaScript array, and import it into your map component.
					Then you apply the styles using
					<InlineCode language='js' text='defaultOptions.' />
				</p>
				<CodeBlock language='js' code={code.mapStyles} />
				<p className='post__paragraph'>
					Now outside of the Map functional component, we just need to
					wrap and export the map data as required.
				</p>
				<CodeBlock language='js' code={code.exportMap} />
				<p className='post__paragraph'>
					Back in App.js we can import our component. Note the{' '}
					<InlineCode language='js' text='loadingElement,' />
					<InlineCode language='js' text='containerElement,' />
					and
					<InlineCode language='js' text='mapElement' />
					properties here. Also see the
					<InlineCode
						language='js'
						text='process.env.REACT_APP_GOOGLE_KEY.'
					/>
					You will need to get your own API key from the
					<NewTab
						text='Google Developer Console'
						url='https://console.developers.google.com/'
					/>
					and set it in a <strong>.env.local</strong> file.
				</p>
				<CodeBlock language='js' code={code.appCode} />
				<p className='post__paragraph'>
					And that's all it takes to create a functional map with
					React! This is just a small project, but there's a lot of
					interesting things could do with React and the Google Maps
					API. Below is the entire Map component used in this project.
				</p>
				<CodeBlock language='js' code={code.mapFile} />
			</div>
		</div>
	);
};

export default NationalParkMap;
