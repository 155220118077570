import React, { useEffect } from 'react';
import NewTab from '../../../Shared/NewTab/NewTab';
import CodeBlock from '../../../Shared/CodeBlock/CodeBlock';
import InlineCode from '../../../Shared/InlineCode/InlineCode';
import ModalImage from 'react-modal-image';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import code from './code';
import '../posts.css';

const YouTubeTrendAnalysis = () => {
	useEffect(() => {
		Prism.highlightAll();
	});

	return (
		<div className='post'>
			<div className='post__title'>
				How to Analyze YouTube Data with React, Node.js, and Chart.js
			</div>
			<div className='post__container'>
				<p className='post__paragraph'>
					The
					<NewTab
						text='YouTube trending page'
						url='https://www.youtube.com/feed/trending'
					/>
					is a great place to see what's popular these days. We're
					going to take a look at some of the data behind these
					videos. To get started, follow the
					<NewTab
						text='YouTube Data API'
						url='https://developers.google.com/youtube/v3/getting-started'
					/>
					instructions for getting an API key.
				</p>
				<p className='post__paragraph'>
					Now we want to create a plain JavaScript file, and
					install/import the
					<NewTab
						text='node-fetch'
						url='https://www.npmjs.com/package/node-fetch'
					/>
					library for fetching the API data. We will also import the
					built-in Node.js
					<NewTab
						text='fs'
						url='https://nodejs.org/api/fs.html#fs_file_system'
					/>
					library to use for writing files.
				</p>
				<CodeBlock language='js' code={code.importText} />
				<p className='post__paragraph'>
					Next we will create a <strong>config.js</strong> file to
					mask the API key value.
				</p>
				<CodeBlock language='js' code={code.config} />
				<p className='post__paragraph'>
					Then we can import that variable and use it in our API call.
					Note the parameters included in the URL below. The
					<InlineCode language='js' text='mostPopular' /> parameter
					gives us the most popular videos. And the
					<InlineCode language='js' text='regionCode' /> parameter
					lets us choose which country to pull data from. You can find
					a list of all the possible parameters
					<NewTab
						text='here.'
						url='https://developers.google.com/youtube/v3/docs/videos/list'
					/>
				</p>
				<CodeBlock language='js' code={code.api} />
				<p className='post__paragraph'>
					Now we can fetch the data and write it to a JSON file.
				</p>
				<CodeBlock language='js' code={code.apiData} />
				<p className='post__paragraph'>
					And this will include an array of videos in the following
					format.
				</p>
				<CodeBlock language='json' code={code.exampleData} />
				<p className='post__paragraph'>
					Next we will extract the data we want to analyze. This will
					also help make everything more readable. So we will loop
					over the array of videos to get the data we want and then
					push that to a new array.
				</p>
				<CodeBlock language='js' code={code.videoProcessing} />
				<p className='post__paragraph'>
					At this point, we can write a new JSON file for the
					processed YouTube data.
				</p>
				<CodeBlock language='js' code={code.writeProcessedFile} />
				<p className='post__paragraph'>
					The data in that file should look like this.
				</p>
				<CodeBlock language='json' code={code.videoData} />
				<p className='post__paragraph'>
					One thing to note is the category number. We don't know what
					YouTube's category numbers represent unless we find their
					reference. So we will make another API call based on
					<NewTab
						text='this documentation.'
						url='https://developers.google.com/youtube/v3/docs/videoCategories/list'
					/>{' '}
					This below script will execute the API call you need.
				</p>
				<CodeBlock language='js' code={code.categories} />
				<p className='post__paragraph'>
					And the JSON data will look like this.
				</p>
				<CodeBlock language='json' code={code.categoryData} />
				<p className='post__paragraph'>
					But all we really care about here is the category id and
					title, so let's loop through and extract that data. You
					could do this in a separate script or within your current
					Node.js script.
				</p>
				<CodeBlock language='js' code={code.processCategories} />
				<p className='post__paragraph'>
					And that gives us an easy mapping of categories to work
					with. (It looks like some numbers are missing, but my guess
					is because YouTube has stopped using them).
				</p>
				<CodeBlock language='json' code={code.categoryMap} />
				<p className='post__paragraph'>
					Now that we know what each category ID represents, we can
					make our first chart using that data. So first let's simply
					add up the total counts that each category occurs. To do
					this, we will create a new script to read and process both
					the YouTube data and Category data.
				</p>
				<CodeBlock language='js' code={code.analyzeCategories} />
				<p className='post__paragraph'>
					And now we have some interesting data! It's the Top 50
					trending YouTube videos by category.
				</p>
				<CodeBlock language='json' code={code.categoryCounts} />
				<p className='post__paragraph'>
					Let's visualize this data to make it easier to compare the
					counts. First install the
					<NewTab
						text='chart.js,'
						url='https://www.npmjs.com/package/chart.js'
					/>
					<NewTab
						text='react-chartjs-2,'
						url='https://www.npmjs.com/package/react-chartjs-2'
					/>
					and
					<NewTab
						text='chartjs-plugin-datalabels'
						url='https://www.npmjs.com/package/chartjs-plugin-datalabels'
					/>
					npm packages. Then we want to create a component to display
					our chart.
				</p>
				<CodeBlock language='js' code={code.categoryComponent} />
				<p className='post__paragraph'>
					If you look at the
					<NewTab
						text='Chart.js'
						url='https://www.chartjs.org/docs/latest/'
					/>
					documentation you can see how data is used to generate a
					chart. So want two arrays, one for numbers and one for
					labels. We will convert our object to a sorted
					two-dimensional array. Then we will loop through that to
					create our numbers and labels arrays.
				</p>
				<CodeBlock language='js' code={code.processChartData} />
				<p className='post__paragraph'>
					Now we want to create
					<InlineCode language='js' text='data' /> and
					<InlineCode language='js' text='options' /> objects for our
					chart. There is a lot of customization you can do here, but
					we'll keep it simple for this demo.
				</p>
				<CodeBlock language='js' code={code.categoryOptions} />
				<p className='post__paragraph'>
					Now we just return the chart in our component.
				</p>
				<CodeBlock language='js' code={code.returnCategoryChart} />
				<p className='post__paragraph'>
					And here is the chart we created! It gives us a useful
					overview of which videos are trending on YouTube.
				</p>
				<ModalImage
					className='post__screenshot national-park-map'
					small='../images/projects/youtube-trend-analysis/categories.png'
					large='../images/projects/youtube-trend-analysis/categories.png'
				/>
				<p className='post__paragraph'>
					There are a lot of interesting things you could do from
					here. You could generate a daily report of this data and
					analyze numbers such as the duration, view count,
					likes/dislikes, etc. The YouTube API gives us a lot of
					insight into videos, and with Node.js and Chart.js we can
					effectively parse, process, and visualize that data.
				</p>
			</div>
		</div>
	);
};

export default YouTubeTrendAnalysis;
