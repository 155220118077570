import React from 'react';

const InlineCode = (props) => {
    // If last character is period, then adjust trailing space
    let lastChar = props.text[props.text.length - 1];

    let trailingSymbol = lastChar === '.' || lastChar === ',';

    let text = props.text;

    if (trailingSymbol) text = text.slice(0, -1);

    return (
        <>
            {' '}
            <code className={`language-${props.language}`}>{text}</code>
            {!trailingSymbol && ' '}
            {trailingSymbol && lastChar + ' '}
        </>
    );
};

export default InlineCode;
