const code = {
	visibilityHidden: `#sidebar,
.left-sidebar,
.js-filter-btn {
  visibility: hidden;
}`,
	displayNone: `.js-favorite-count,
.js-comments-container,
.js-show-link,
.comment,
.mb0,
.js-products-menu,
.answers-subheader,
.everyonelovesstackoverflow,
.post-taglist,
.js-add-link,
.js-post-issue,
.site-footer--container,
.s-btn__primary,
.bottom-notice,
#post-form,
#search + ol,
#question-header + div {
  display: none !important;
}`,
	answerBorder: `.answer:nth-of-type(2) {
    border: 5px solid darkgreen !important;
    border-radius: 5px;
    padding: 10px !important;
    padding-bottom: 0px !important;
    margin-bottom: 25px !important;
}`,
	voteCount: `let votes = document.getElementsByClassName("js-vote-count");
    
for (let i = 1; i < votes.length; i++) {
    let element = votes[i];
    let voteCount = parseInt(element.getAttribute("data-value"));
    if (voteCount < 1) {
        let num = i + 2;
        let result = document.querySelector(".answer:nth-of-type(" + num + ")");
        if (result) {
            result.style.display = "none";
        }
    }
}`,
	manifest: `{
  "name": "Minimalist Stack Overflow",
  "description": "A minimalist theme for Stack Overflow",
  "manifest_version": 1,
  "version": "1.0.0",
  "icons": {
    "48": "icon_48.png",
    "128": "icon_128.png"
  },
  "browser_action": {
    "default_icon": "icon_16.png"
  },
  "content_scripts": [
    {
      "css": [
        "style.css"
      ],
      "js": [
        "script.js"
      ],
      "matches": [
        "https://stackoverflow.com/questions*"
      ]
    }
  ]
}`,
};

export default code;
