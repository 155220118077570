import React from 'react';

import './About.css';

const About = () => {
	return (
		<div class='about'>
			Learn Code by Doing was created by me,{' '}
			<a
				href='https://timfinnigan.com'
				target='_blank'
				rel='noopener noreferrer'
			>
				Tim Finnigan
			</a>
			.
			<p>
				I will periodically add new tutorials here on various coding
				projects that I find interesting.
			</p>
			<p>
				Please reach out if you have any comments, questions, or
				feedback!
			</p>
			{/* <p>
				My philosophy is that we learn best by doing. Instead of
				passively watching tutorials, I think it is better to actively
				build projects.
			</p>
			<p>
				I also believe in working on projects that align with your
				personal interests. We are more motivated and engaged when
				working on things that appeal to us.
			</p>
			<hr />
			<p>The learning process I follow and recommend is:</p>
			<ol>
				<li>
					<strong>Demystification</strong>: Finding a project that you
					can follow along with and understand.
				</li>
				<li>
					<strong>Experimentation</strong>: Trying to build your own
					project.
				</li>
				<li>
					<strong>Reinforcement</strong>: Applying those skills to
					other projects or teaching others.
				</li>
			</ol>
			<hr />
			<p>
				Many people debate the merits of being a specialist versus a
				generalist. I believe this is a false dichotomy. Your skills can
				go both wide and deep. You can both know a little about a lot of
				things and a lot about one thing.
			</p>
			<p>
				I also believe in a "Swiss Army" approach to these skills. Each
				skill you learn becomes a new tool in your toolkit that you can
				use as needed.
			</p>
			<p>
				The challenges we encounter are often complex and intimidating,
				but the more we know about a wide range of topics, the better
				prepared we will be to face them.
			</p> */}
		</div>
	);
};

export default About;
