import React from 'react';

const CodeBlock = (props) => {
    return (
        <pre className='post__highlight'>
            <code className={`language-${props.language}`}>{props.code}</code>
        </pre>
    );
};

export default CodeBlock;
