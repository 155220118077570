const code = {
    importText: `const puppeteer = require('puppeteer');
const fs = require('fs');`,
    tryCatch: `(async () => {
    try {

    } catch (err) {
        console.log(err);
    }
})();`,
    tryBlock: `let browser = await puppeteer.launch();
let page = await browser.newPage();
await page.goto('https://news.ycombinator.com/');`,
    evaluate: `let posts = await page.evaluate(() => {
    let results = [];
    let items = document.querySelectorAll('a.storylink');
    let ages = document.querySelectorAll('span.age');
    for (let i = 0; i < items.length; i++) {
        results.push({
            title: items[i].innerText,
            source: items[i].getAttribute('href'),
            age: ages[i].innerText,
        });
    }
    return results;
});`,
    saveFile: `browser.close();

fs.writeFile('../hacker-news.json', JSON.stringify(posts), function (
    err
) {
    if (err) throw err;
    console.log('JSON file saved!');
});`,
    jsonData: `{
    "title": "Your Response to Mistakes Defines You (2014)",
    "source": "https://fs.blog/2014/09/mistakes/",
    "age": "2 hours ago"
},
{
    "title": "Why do we use the Linux kernel's TCP stack? (2016)",
    "source": "https://jvns.ca/blog/2016/06/30/why-do-we-use-the-linux-kernels-tcp-stack/",
    "age": "3 hours ago"
},
{
    "title": "The open source paradox",
    "source": "http://antirez.com/news/134",
    "age": "6 hours ago"
},`,
    allCode: `const puppeteer = require('puppeteer');
const fs = require('fs');

(async () => {
    try {
        const browser = await puppeteer.launch();
        const page = await browser.newPage();
        await page.goto('https://news.ycombinator.com/');
        let posts = await page.evaluate(() => {
            let results = [];
            let items = document.querySelectorAll('a.storylink');
            let ages = document.querySelectorAll('span.age');
            for (let i = 0; i < items.length; i++) {
                results.push({
                    title: items[i].innerText,
                    source: items[i].getAttribute('href'),
                    age: ages[i].innerText,
                });
            }
            return results;
        });

        browser.close();

        fs.writeFile('../hacker-news.json', JSON.stringify(posts), function (
            err
        ) {
            if (err) throw err;
            console.log('JSON file saved!');
        });
    } catch (err) {
        console.log(err);
    }
})();
`,
};

export default code;
