import React, { useEffect } from 'react';
import NewTab from '../../../Shared/NewTab/NewTab';
import CodeBlock from '../../../Shared/CodeBlock/CodeBlock';
import InlineCode from '../../../Shared/InlineCode/InlineCode';
import ModalImage from 'react-modal-image';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import './StackOverflowExtension.css';

import code from './code';

import '../posts.css';

const StackOverflowExtension = () => {
	useEffect(() => {
		Prism.highlightAll();
	});

	return (
		<div className='post'>
			<div className='post__title'>
				How to Make a Minimalist Stack Overflow Theme
			</div>
			<div className='post__container'>
				<p className='post__paragraph'>
					Like many developers, I use
					<NewTab
						text='Stack Overflow'
						url='https://stackoverflow.com/'
					/>
					regularly. But sometimes I feel like it's too hard to find
					the answers I'm looking for. Take this screenshot for
					example. There is a lot of information here that is not
					relevant to what I'm interested in.
				</p>
				<ModalImage
					className='post__screenshot stack-overflow-before'
					small='../images/projects/stack-overflow-extension/stack-overflow-before.png'
					large='../images/projects/stack-overflow-extension/stack-overflow-before.png'
				/>
				<p className='post__paragraph'>
					So we're going to design a Chrome extension to enable this
					view, removing any content that is not directly relevant to
					most users. The majority of people just want to quickly find
					an answer to their question. They don't need all the
					sidebars, sub-headings, and so on.
				</p>
				<ModalImage
					className='post__screenshot stack-overflow-after'
					small='../images/projects/stack-overflow-extension/stack-overflow-after.png'
					large='../images/projects/stack-overflow-extension/stack-overflow-after.png'
				/>
				<p className='post__paragraph'>
					The first step to find is elements that you want to hide.
					This is easy to do with Chrome Developer Tools. For example,
					here we inspect the sidebar and find it has the id
					"left-sidebar".
				</p>
				<ModalImage
					className='post__screenshot'
					small='../images/projects/stack-overflow-extension/inspect-elements.png'
					large='../images/projects/stack-overflow-extension/inspect-elements.png'
				/>
				<p className='post__paragraph'>
					Now we want to create a CSS file in order to track all the
					ids and classes that we want to hide. We will add
					<InlineCode language='css' text='visibility: hidden' /> to
					these here first.
				</p>
				<CodeBlock language='css' code={code.visibilityHidden} />
				<p className='post__paragraph'>
					It is important to note the difference between
					<InlineCode language='css' text='visibility: hidden' /> and
					<InlineCode language='css' text='display: none.' /> The
					first one will simply hide an element, while the latter will
					but hide an element and remove it from the DOM. This
					<NewTab
						text='post on Stack Overflow'
						url='https://stackoverflow.com/questions/133051/what-is-the-difference-between-visibilityhidden-and-displaynone'
					/>
					does a good job of elaborating on that distinction. Below
					are all the ids and divs that we want to remove. Note that
					we use the <InlineCode language='css' text='!important' />
					rule to override any other styles applied to these elements.
				</p>
				<CodeBlock language='css' code={code.displayNone} />
				<p className='post__paragraph'>
					Many people might disagree about hiding the comments on
					Stack Overflow. There are often valuable and interesting
					discussions in the comments. But they also can contain
					irrelevant information, so I decided to hide them here.
				</p>
				<p className='post__paragraph'>
					The last thing that we want to add here is that green border
					around the top answer.
				</p>
				<CodeBlock language='css' code={code.answerBorder} />
				<p className='post__paragraph'>
					Now we can make this theme more dynamic by adding a
					JavaScript file as well. In this example, I capture the vote
					count and hide any answer that has no upvotes.
				</p>
				<CodeBlock language='js' code={code.voteCount} />
				<p className='post__paragraph'>
					Again, some users may want to see this content. But I'd
					rather not see the posts with either zero or negative points
					because they usually aren't helpful to me.
				</p>
				<p className='post__paragraph'>
					Now we just need a <strong>manifest.json</strong> file to
					finish making our Chrome extension. Note that we have three
					square shaped icons here in different sizes (16px, 48px and
					128px). Then we add a reference to our CSS and JS file in{' '}
					<strong>content_scripts</strong>. Also note the{' '}
					<strong>matches</strong> value, which will ensure that this
					extension only applies to websites that include that text in
					the URL.
				</p>
				<CodeBlock language='json' code={code.manifest} />
				<p className='post__paragraph'>
					Now we want to open chrome://extensions and add the folder
					with all our files in it. Click "Load Unpacked Extension" at
					the top to import your folder. And that's all it takes! You
					have now customized the design and layout of Stack Overflow.
					Imagine all the possibilities now with other sites that you
					could customize however you want!
				</p>
			</div>
		</div>
	);
};

export default StackOverflowExtension;
