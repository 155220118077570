import React from 'react';
import { NavLink } from 'react-router-dom';

import './Projects.css';

import projectList from './project-list';

const Projects = () => {
	return (
		<div className='projects'>
			{projectList.slice(0).map((item, index) => (
				<>
					{item.udemy && (
						<div className='projects__project'>
							<a
								href={item.link}
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									className='projects__project--icon'
									src={`../images/projects/${item.path}.png`}
									alt={item.path}
								/>
								<div className='projects__project--title'>
									{item.title}
								</div>
							</a>
						</div>
					)}
					{!item.udemy && (
						<NavLink
							to={item.path}
							key={index}
							className='projects__project'
						>
							<img
								className='projects__project--icon'
								src={`../images/projects/${item.path}.png`}
								alt={item.path}
							/>
							<div className='projects__project--title'>
								{item.title}
							</div>
						</NavLink>
					)}
				</>
			))}
		</div>
	);
};

export default Projects;
