import React from 'react';
import { NavLink } from 'react-router-dom';

import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar = () => {
    return (
        <header className='header'>
            <NavLink exact={true} to={'/'} className='header__title'>
                <FontAwesomeIcon icon='code' className='header__icon' />
                Learn Code By Doing
            </NavLink>
            <nav className='nav'>
                <NavLink
                    exact={true}
                    to={'/'}
                    className='nav__link'
                    activeClassName='nav__link--active'
                >
                    <FontAwesomeIcon icon='home' className='nav__icon' />
                    Home
                </NavLink>
                <NavLink
                    to={'/about'}
                    className='nav__link'
                    activeClassName='nav__link--active'
                >
                    <FontAwesomeIcon icon='info-circle' className='nav__icon' />
                    About
                </NavLink>
            </nav>
        </header>
    );
};

export default Navbar;
